import './bootstrap';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/inertia-react';
import {InertiaProgress} from '@inertiajs/progress';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import stores from "@/Stores";
import {Provider} from 'mobx-react';
import {isEmpty} from "lodash";

import.meta.glob([
    '../fonts/**',
]);

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({el, App, props}) {
        const root = createRoot(el);

        root.render(
            <Provider stores={stores}>
                <App {...props} />
            </Provider>
        );
    },
}).then(r => !isEmpty(r) ? console.log('r', r) : '');

InertiaProgress.init({color: '#4B5563'});
