import {observable, configure, makeObservable} from 'mobx';

export default class ProjectsStore {

    constructor() {
        makeObservable(this);

        configure({
            enforceActions: "never",
        })
    }

    @observable projectsActive = 'hotels'

    projectsList = [
        {
            category: 'Отели',
            categoryAlias: 'hotels',
            name: 'Отель "Планерное", г.Химки',
            alias: 'otel-planernoe-g-himki',
            text: 'Модернизация системы видеонаблюдения.\n' +
                'Замена системы на IP.\n' +
                'Монтаж 96 камер.',
            img: [
                '/storage/img/main/projects/otel-planernoe-g-himki/1.webp',
                '/storage/img/main/projects/otel-planernoe-g-himki/2.webp',
                '/storage/img/main/projects/otel-planernoe-g-himki/3.webp',
                '/storage/img/main/projects/otel-planernoe-g-himki/4.webp',
            ]
        },
        {
            category: 'Бизнес-центры',
            categoryAlias: 'businessCenters',
            name: 'IT Park Зеленодольск',
            alias: 'it-park',
            text: 'Реконструкция здания телефонной станции, ' +
                'или строительство IP Park Зеленодольск, ' +
                'монтаж 13 камер видеонаблюдения',
            img: [
                '/storage/img/main/projects/it-park/1.webp',
                '/storage/img/main/projects/it-park/2.webp',
                '/storage/img/main/projects/it-park/3.webp',
                '/storage/img/main/projects/it-park/4.webp',
            ]
        }
    ].map((n, i) => ({...n, id: i + 1}));
}
