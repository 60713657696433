import {observable, makeObservable, configure} from 'mobx';
import axios from 'axios';

export default class ModalStore {

    @observable openModal = false;
    @observable formLoadModal = false;
    @observable answerModal = false;
    @observable loaderModal = false;
    answerTextModal = 'Спасибо. Ваша заявка получена, наши менеджеры свяжутся с вами в ближайшее время!';
    titleModal = 'Оставьте заявку и мы перезвоним';
    idModal = '';
    btnNameModal = 'Заказать звонок';

    constructor() {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
    }

    controlModal = () => {
        this.openModal = !this.openModal
    }

    mailSend = async (values) => {
        return await axios.post(`/api/mailSend`, values);
    }
}
