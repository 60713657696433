import {observable, makeObservable, configure} from 'mobx';

export default class CalculatorStores {
    @observable resultCalculation = 0
    @observable formLoadCalculation = false;

    //Площадь
    square = 100

    //Стоимость кабеля
    cableCost = 38

    //Количество камер
    numberCameras = 1

    //Стоимость камеры
    cameraCost = 2523

    //Стоимость разъема
    connectorCost = 35

    //Монтажные работы
    installationWork = 2500

    //Стоимость регистратора
    registrarCost = 5032
    //Видеорегистратор да/нет
    registrarFlag = true

    //Стоимость аккумулятора
    batteryCost = 650
    //Аккумуляторная батарея да/нет
    batteryFlag = true

    //Стоимость жесткого диска
    hardDriveCost = 4700

    //Стоимость блока питания
    powerSupplyCost = 1488


    constructor() {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })

        this.calculation()
    }

    calculationSquareChange = (square) => {
        this.square = square
        this.calculation()
    }

   calculation = () => {
        this.resultCalculation =
            //Корень с площади * на стоимость кабеля
            (Math.round(Math.sqrt(this.square)) * this.cableCost) +
            //Количество камер * на стоимость камеры
            ( this.numberCameras * this.cameraCost) +
            //Разъемы 1 штука идет на 3 камеры * на стоимость разъема
            ((this.numberCameras < 3 ? 1 : Math.ceil(this.numberCameras / 3)) * this.connectorCost) +
            //Монтажные работы * кол камер
            (this.installationWork * this.numberCameras) +
            (this.registrarFlag ? this.registrarCost : 0) +
            (this.batteryFlag ? this.batteryCost : 0) +
            this.hardDriveCost +
            this.powerSupplyCost;
    }

    mailSend = async (values) => {
        return await axios.post(`/api/mailSend`, values);
    }
}
