import {observable, makeObservable, configure} from 'mobx';

export default class EstimateStores {

    @observable formLoader = false;

    constructor() {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
    }

}
