import {observable, makeObservable, configure} from 'mobx';

export default class PKModalStore {

    @observable openPKModal = false;

    constructor() {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
    }

    controlPKModal = () => {
        this.openPKModal = !this.openPKModal
    }
}
