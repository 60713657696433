import {observable, makeObservable, configure} from 'mobx';

export default class MobileMenuStore {

    @observable openMobileMenu = false;

    constructor() {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
    }

    openMobileMenuOpen = () => {
        this.openMobileMenu = true
    }

    openMobileMenuClose= () => {
        this.openMobileMenu = false
    }
}
