import {observable, configure, makeObservable} from 'mobx';

export default class ForWhatStore {

    constructor() {
        makeObservable(this);

        configure({
            enforceActions: "never",
        })
    }

    forWhatChip = [
        {id: 1, alias: 'shop', name: 'Магазин'},
        {id: 2, alias: 'cafeRestaurant', name: 'Кафе/Ресторан'},
        {id: 3, alias: 'store', name: 'Склад'},
        {id: 4, alias: 'businessCenters', name: 'Бизнес-центры'},
        {id: 5, alias: 'office', name: 'Офис'},
        {id: 6, alias: 'construction', name: 'Стройка'},
        {id: 7, alias: 'parking', name: 'Парковки'},
        {id: 8, alias: 'houseApartment', name: 'Дом/квартира'},
        {id: 9, alias: 'carService', name: 'Автосервис'},
        {id: 10, alias: 'beautySaloon', name: 'Салон красоты'},
        {id: 11, alias: 'residentialComplex', name: 'Жилой комплекс'},
    ];

    @observable forWhatListActive = 'shop'

    forWhatList = [
        {
            id: 1,
            alias: 'shop',
            name: 'Магазин',
            img: '/storage/img/main/forWhat/shop.webp',
            list: [
                'Контролируйте процесс оплаты',
                'Предотвратите кражу и порчу товара',
                'Помогает разобраться в спорных ситуациях',
                'Анализируйте поведения покупателей и контролируйте очередь',
                'Контролируйте работу сотрудников и качество обслуживания',
                'Выявляйте подозрительных лиц в торговом зале',
                'Контролируйте приемку и хранение товара',
                'Следите за безопасностью вблизи магазина, исключите вандализм'
            ]
        },
        {
            id: 2,
            alias: 'cafeRestaurant',
            name: 'Кафе/Ресторан',
            img: '/storage/img/main/forWhat/cafeRestaurant.webp',
            list: [
                'Мониторьте активность официантов в зале',
                'Фиксируйте время работы и отдыха сотрудников',
                'Выявляйте случаи мошенничества',
                'Следите за соблюдением норм и технологией приготовления блюд',
                'Присматривайте за сохранностью продуктов и оборудования',
                'Безопасность сотрудников и клиентов',
                'Помогает разобраться в спорных ситуациях',
                'Фиксируйте всех, кто входит и выходит из заведения',
            ]
        },
        {
            id: 3,
            alias: 'store',
            name: 'Склад',
            img: '/storage/img/main/forWhat/store.webp',
            list: [
                'Контролируйте приемку и отгрузку товаров',
                'Следите за сохранностью товаров',
                'Фиксируйте, как сотрудники формируют заказы ',
                'Присматривайте за выдачей товара',
                'Выявляйте посторонних лиц',
                'Фиксируйте случаи повреждения товаров',
                'Предотвращайте воровство',
                'Следите за техникой безопасности'
            ]
        },
        {
            id: 4,
            alias: 'businessCenters',
            name: 'Бизнес-центры',
            img: '/storage/img/main/forWhat/businessCenters.webp',
            list: [
                'Следите за безопасностью на всей территории',
                'Предотвращайте воровство и порчу имущества',
                'Фиксируйте происходящее во всех зонах',
                'Контролируйте происходящее в офисных помещениях ',
                'Фиксируйте посетителей и выявляйте посторонних',
                'Распознавайте автомобильные номера',
                'Следите за безопасностью возле бизнес-центра',
                'Следите за трудовой дисциплиной персонала и его поведением'
            ]
        },
        {
            id: 5,
            alias: 'office',
            name: 'Офис',
            img: '/storage/img/main/forWhat/office.webp',
            list: [
                'Выявляйте посторонних лиц и нарушителей порядка',
                'Предотвращайте конфликтные ситуации',
                'Контролируйте сохранность денежных средств',
                'Контролируйте трудовую дисциплину сотрудников и их поведение',
                'Фиксируйте воровство и утечку служебной информации',
                'Присматривайте, как персонал ведет себя с клиентами ',
                'Следите за сохранностью оборудования, мебели и техники',
                'Собирайте доказательную базу на случай спорных ситуаций'
            ]
        },
        {
            id: 6,
            alias: 'construction',
            name: 'Стройка',
            img: '/storage/img/main/forWhat/construction.webp',
            list: [
                'Наблюдайте за ходом строительства',
                'Выявляйте нарушения строительных норм',
                'Контролируйте ход основных работ',
                'Контролируйте работу и поведение строительной команды',
                'Отслеживайте сохранность инвентаря и стройматериалов',
                'Будьте в курсе, кто посещает объект',
                'Контролируйте поставки материалов и вывоз мусора',
                'Фиксируйте нарушения техники безопасности'
            ]
        },
        {
            id: 7,
            alias: 'parking',
            name: 'Парковки',
            img: '/storage/img/main/forWhat/parking.webp',
            list: [
                'Следить за безопасностью на всей территории',
                'Решать спорные ситуации при повреждении машины',
                'Следите за зоной, где чаще всего происходят ДТП',
                'Предотвращать угон автомобилей и случаи хулиганства',
                'Распознавать и фиксировать автомобильные номера',
                'Находить свободные места на парковке',
                'Контролируйте работу охранников',
                'Контролируйте посетителей, выявляйте подозрительных лиц'
            ]
        },
        {
            id: 8,
            alias: 'houseApartment',
            name: 'Дом/квартира',
            img: '/storage/img/main/forWhat/houseApartment.webp',
            list: [
                'Круглосуточный контроль за происходящим в доме и его пределами',
                'Проверка работы персонала в ваше отсутствие (няни, домработница, садовник, строитель)',
                'Наблюдение за детьми',
                'Поможет выявить и опознать злоумышленника',
                'Система видеонаблюдения отпугнет грабителей и воров'
            ]
        },
        {
            id: 9,
            alias: 'carService',
            name: 'Автосервис',
            img: '/storage/img/main/forWhat/carService.webp',
            list: [
                'Следить за безопасностью на всей территории',
                'Выявлять подозрительные лица и автомобили',
                'Следите за уровнем и временем обслуживания ваших клиентов',
                'Предотвращать хулиганство, хищение и порчу комплектующих',
                'Собирать доказательную базу на случаи спорных ситуаций',
                'Контролируйте приемку товара и его хранение',
                'Прозрачность работы сервиса: клиенты могут наблюдать за всеми процессами',
                'Контролируйте процесс оплаты, исключите воровство'
            ]
        },
        {
            id: 10,
            alias: 'beautySaloon',
            name: 'Салон красоты',
            img: '/storage/img/main/forWhat/beautySaloon.webp',
            list: [
                'Анализировать поведение клиентов в салоне',
                'Предотвращать хулиганство и порчу имущества',
                'Следите за уровнем обслуживания ваших клиентов',
                'Контролировать рабочую дисциплину и поведение мастеров',
                'Фиксируйте всех, кто входит и выходит из салона или парикмахерской',
                'Контролируйте процесс оплаты, исключите воровство',
                'Следите за безопасностью вблизи салона или парикмахерской',
                'Собирать доказательную базу на случаи спорных ситуаций'
            ]
        },
        {
            id: 11,
            alias: 'residentialComplex',
            name: 'Жилой комплекс',
            img: '/storage/img/main/forWhat/residentialComplex.webp',
            list: [
                'Безопасность всех проживающих в доме',
                'Контроль детей во время прогулки',
                'Предотвращать хулиганство и порчу имущества',
                'Помощь при расследовании инцидентов, сбор улик, в том числе в поимке преступников и правонарушителей.',
                'Решать спорные ситуации при повреждении машины',
                'Следить за безопасностью на всей территории',
                'Выявлять подозрительные лица и автомобили',
                'Распознавать и фиксировать автомобильные номера'
            ]
        }
    ];
}
