import {observable, action, makeObservable, configure} from 'mobx';

export default class FilesManagerStore {

    @observable openModalFlag = false;
    @observable extension = 'ВСЕ';
    @observable sortFiles = 'time';
    pathAddImageSet;

    constructor() {
        makeObservable(this);
        configure({
            enforceActions: "never",
        })
    }

    @action closeModalFilesManager = () => {
        this.openModalFlag = false;
    };

    @action  openModalFilesManager = () => {
        this.openModalFlag = true;
    };
}
