import ForWhatStore from "@/Stores/ForWhatStore";
import FilesManagerStore from "@/Stores/FilesManagerStore";
import ModalStore from "@/Stores/ModalStore";
import MobileMenuStore from "@/Stores/MobileMenuStore";
import ProjectsStore from "@/Stores/ProjectsStore";
import PKModalStore from "@/Stores/PKModalStore";
import CalculatorStores from "@/Stores/CalculatorStores";
import EstimateStores from "@/Stores/EstimateStores";



class RootStore {
    constructor() {
        this.api = {};

        this.localStorage = localStorage;
        this.filesManagerStore = new FilesManagerStore();
        this.mobileMenuStore = new MobileMenuStore();
        this.forWhatStore = new ForWhatStore();
        this.projectsStore = new ProjectsStore();
        this.calculatorStores = new CalculatorStores();
        this.estimateStores = new EstimateStores();

        this.modalStore = new ModalStore();
        this.pkModalStore = new PKModalStore();
    }
}

export default new RootStore();
